<template>
  <div>
    <v-row v-if="loading || error" justify="center">
      <v-col cols="12" md="11">
        <v-card elevation="0">
          <v-card-title class="black white--text mb-2">
            RTB
          </v-card-title>
          <v-card-text>
            <v-progress-circular v-if="loading" indeterminate color="primary" />
            <v-input v-else-if="error" :error-messages="['Error']" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <router-view v-else />
  </div>
</template>

<script>
  import { mapActions } from 'vuex'

  export default {
    name: 'Rtb',
    data() {
      return {
        loading: false,
        error: false
      }
    },
    watch: {
      $route: {
        immediate: true,
        handler: 'checkNetworkStatus'
      }
    },
    methods: {
      checkNetworkStatus() {
        this.loading = true
        this.error = false
        this.fetchNetwork()
          .then((network) => {
            if (this.checkIfLeaved()) return

            if (
              network.latest_approval.status === 'approved'
              || network.latest_approval.status === null
            ) {
              if (
                !['RtbEndpoints', 'RtbCreateEndpoint', 'RtbEditEndpoint'].includes(this.$route.name)
              ) {
                this.$router.replace({ name: 'RtbEndpoints' })
              }
            } else if (!['RtbNetwork', 'RtbCreateNetwork'].includes(this.$route.name)) {
              this.$router.replace({ name: 'RtbNetwork' })
            }
          })
          .catch(() => {
            if (this.checkIfLeaved()) return

            if (!['RtbNetwork', 'RtbCreateNetwork'].includes(this.$route.name)) {
              this.$router.replace({ name: 'RtbNetwork' })
            }
          })
          .finally(() => {
            this.loading = false
          })
      },
      checkIfLeaved() {
        return !this.$route.name.match(/^Rtb/)
      },
      ...mapActions('rtb', ['fetchNetwork'])
    }
  }
</script>
